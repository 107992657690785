<template>
    <v-container fluid class="d-flex justify-end align-center">
        <!--REFRESH-->
        <v-btn
        color="primary"
        fab
        medium
        dark
        class="ml-5 mr-5"
        @click="refreshData"
        >
            <v-icon>mdi-refresh</v-icon>
        </v-btn>
    </v-container>
</template>

<script>
export default {
    props:{
        refreshData: { type: Function }
    }
}
</script>