<template>
  <v-container fluid>
    <!--Breadcrumbs--> 
    <Breadcrumbs :breadcrumbs="breadcrumbs"/>

    <data-table 
      :headers="headers"
      :data="data"
      :loading="loading"
      :options="options"
      >
        <component :is="headerChildComponent" :refreshData="getPlans"></component>
      </data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { itemsSpan, initialize } from '../helpers/index';
import DataTable from '../components/common/DataTable.vue';
import Breadcrumbs from '../components/common/Breadcrumbs.vue';
import DataTableHeader from '../components/common/DataTableHeader.vue';
export default {
  components:{
    DataTable,
    Breadcrumbs,
    DataTableHeader
  },
  data(){
    return{
        breadcrumbs: [
            {
                text: 'Dashboard',
                href: '/',
            },
            {
                text: 'Plans',
                href: '/plans',
            },
        ],
        headers: [
            { text: 'Created', value: 'created_at' },
            { text: 'Name', value: 'name' },
            { text: 'Amount', value: 'amount' },
            { text: 'Report limit', value: 'report_limit' },
            { text: 'Subscription type', value: 'subscription_type' },
            { text: 'Premium support', value: 'premium_support' },
            { text: 'Enterprise', value: 'is_enterprise' },
            { text: 'Active', value: 'status' },
        ],
        headerChildComponent: 'DataTableHeader',
        options:{
          timestamps: [ 'created_at' ]
        }
    }
  },
  computed: {
    ...mapState('plans', ['data', 'loading']),
    itemsSpan,
    plansPageNumber: {
        get () {
            return this.pageNumber
        },
        set (value) {
            this.UPDATE_PAGE_NUMBER(value)
        }
    }
  },
  methods:{
    ...mapActions('plans', ['getPlans']),
    ...mapMutations('plans', ['UPDATE_PAGE_NUMBER']),
    initialize,
    paginate(pageNumber){

      this.plansPageNumber = pageNumber

      this.getPlans();

    }
  },
  created(){
    this.initialize()

    this.getPlans()
  }
}
</script>

<style scoped>
.status-chip{
  display: grid;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  width: 120px;
}
.breadcrumb-link{
        color: #0000008a !important;
    }
</style>